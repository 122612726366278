/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// APIs
import { LINK_API } from '../shared/APIs';

// Assets
import PCN_ICON from '../images/PCN/male.svg';

// Style
import '../page-styles/PCN.scss';

/* -------------------------------------------------------------------------- */
/*                         Form Contact PCN Page                              */
/* -------------------------------------------------------------------------- */

function FormContactPCNPage({ location }) {
  /* ********************************* CONSTS ********************************* */

  const IS_BROWSER = typeof window !== 'undefined';
  const INITIAL_FORM_STATE = {
    first_name: '',
    last_name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
    phone: '',
  };

  /* ********************************** HOOKS ********************************* */
  // Localization
  // const { t } = useTranslation();

  const [contactedPerson, setContactedPerson] = useState({});
  const [successSubmission, setSuccessSubmission] = useState(false);
  const [errorSubmission, setErrorSubmission] = useState(false);
  const [contactForm, setContactForm] = useState(INITIAL_FORM_STATE);

  useEffect(() => {
    if (IS_BROWSER) {
      const getPerson = JSON.parse(localStorage.getItem('contacted_person'));
      setContactedPerson(getPerson);
    }
  }, [IS_BROWSER]);

  /* ******************************** CALLBACKS ******************************* */

  const onFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${LINK_API}/api/contact-pcns`, {
        data: {
          ...contactForm,
        },
      })
      .then(() => {
        setSuccessSubmission(true);
        setTimeout(() => {
          setSuccessSubmission(false);
        }, 3000);
      })
      .catch(() => {
        setErrorSubmission(true);
        setTimeout(() => {
          setErrorSubmission(false);
        }, 2000);
      });
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="PCN" />
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              {location.pathname.split('/')[1]}
            </Link>
          </Container>
        </Visible>
      </Container>
      <Container className="form-wrapper flex justify-center fluid" fluid>
        <Container className="form-container flex flex-wrap">
          <Container className="PCN-info text-center">
            <img src={PCN_ICON} alt="PCN" />
            <h5 className="text-left">
              {`${contactedPerson?.attributes?.last_name} ${contactedPerson?.attributes?.first_name}`}
            </h5>
          </Container>

          <Container
            className="PCN-form form-contact-pcn"
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <form onSubmit={onFormSubmit}>
              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="firstName"
                  type="text"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      first_name: e.target.value,
                    })
                  }
                  required
                />
                <label htmlFor="firstName">Nom</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="lastName"
                  type="text"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      last_name: e.target.value,
                    })
                  }
                  required
                />
                <label htmlFor="lastName">Prénom</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="email"
                  type="email"
                  onChange={(e) =>
                    setContactForm({ ...contactForm, email: e.target.value })
                  }
                  required
                />
                <label htmlFor="email">Votre adresse électronique</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="topic"
                  type="text"
                  onChange={(e) =>
                    setContactForm({ ...contactForm, subject: e.target.value })
                  }
                  required
                />
                <label htmlFor="topic">Sujet</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <textarea
                  className="form-control"
                  id="message"
                  type="message"
                  onChange={(e) =>
                    setContactForm({ ...contactForm, message: e.target.value })
                  }
                  required
                />
                <label htmlFor="message">Votre message</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="institution"
                  type="text"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      organization: e.target.value,
                    })
                  }
                  required
                />
                <label htmlFor="institution">Institution / Organisme</label>
              </Container>

              <Container className="form-group fluid" fluid>
                <input
                  className="form-control"
                  id="phone"
                  type="number"
                  onChange={(e) =>
                    setContactForm({ ...contactForm, phone: e.target.value })
                  }
                  required
                />
                <label htmlFor="phone">Téléphone</label>
              </Container>

              <Container className="form-control file-aria fluid" fluid>
                <label htmlFor="file">Pièce jointe</label>
                <span className="upload-label text-center">
                  CHOISIR UN FICHIER
                </span>
                <input
                  type="file"
                  id="file"
                  className="upload-box"
                  placeholder="CHOISIR UN FICHIER"
                  multiple="multiple"
                />
              </Container>
              <Container className="form-control file-aria fluid" fluid>
                <button className="submit-contact" type="submit">
                  ENVOYER
                </button>
              </Container>
              <Container className="form-control top fluid">
                {successSubmission && !errorSubmission && (
                  <p className="success-msg">
                    Votre formulaire est soumis avec succès..
                  </p>
                )}
                {errorSubmission && !successSubmission && (
                  <p className="error-msg">
                    Votre formulaire est soumis avec échec..
                  </p>
                )}
              </Container>
            </form>
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

FormContactPCNPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default FormContactPCNPage;
